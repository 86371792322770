.pol-dropdown {
    position: relative;
    display: inline-block;
}

.pol-dropdown__content {
    position: absolute;
    z-index: 999;
    top: 100%;
    display: inline-block;
    min-width: 100%;
}

.pol-dropdown:not(.is-hoverable) .pol-dropdown__content:not(.is-open) {
    display: none;
}

.pol-dropdown.is-hoverable:not(:hover) .pol-dropdown__content {
    display: none;
}
