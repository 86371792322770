$icomoon-font-family: "pol-icon" !default;
$icomoon-font-path: "fonts" !default;

$policon-Polygone: unquote('"\\e91c"');
$policon-abc: unquote('"\\e946"');
$policon-actions: unquote('"\\e91f"');
$policon-add: unquote('"\\e920"');
$policon-add-client: unquote('"\\e921"');
$policon-add-company: unquote('"\\e922"');
$policon-add-fine: unquote('"\\e945"');
$policon-add-full: unquote('"\\e923"');
$policon-address-book: unquote('"\\f2b9"');
$policon-affichage-icone: unquote('"\\e910"');
$policon-affichage-ligne: unquote('"\\e911"');
$policon-arrow-left: unquote('"\\e912"');
$policon-arrow-right: unquote('"\\e924"');
$policon-arrow-right-bis: unquote('"\\f061"');
$policon-bars: unquote('"\\f0c9"');
$policon-bin: unquote('"\\e913"');
$policon-building: unquote('"\\f1ad"');
$policon-business: unquote('"\\e90f"');
$policon-calculator: unquote('"\\e93c"');
$policon-check-circle: unquote('"\\f058"');
$policon-chevron-circle-left: unquote('"\\f137"');
$policon-chevron-down: unquote('"\\f078"');
$policon-circle-notch: unquote('"\\f1ce"');
$policon-client: unquote('"\\e90a"');
$policon-clock: unquote('"\\e914"');
$policon-clock-full: unquote('"\\e93d"');
$policon-close: unquote('"\\f00d"');
$policon-cog: unquote('"\\f013"');
$policon-cogs: unquote('"\\f085"');
$policon-company: unquote('"\\e909"');
$policon-completed-task: unquote('"\\e908"');
$policon-contact: unquote('"\\e90d"');
$policon-copy: unquote('"\\e935"');
$policon-database: unquote('"\\e952"');
$policon-delete: unquote('"\\e915"');
$policon-document: unquote('"\\e925"');
$policon-download: unquote('"\\e926"');
$policon-draggable: unquote('"\\e937"');
$policon-dwl-csv: unquote('"\\e927"');
$policon-edit: unquote('"\\e916"');
$policon-edit-client: unquote('"\\e917"');
$policon-engrenage: unquote('"\\e928"');
$policon-envelope: unquote('"\\f0e0"');
$policon-envelope-open-text: unquote('"\\e934"');
$policon-euro: unquote('"\\e947"');
$policon-euro-sign: unquote('"\\f153"');
$policon-exclamation-circle: unquote('"\\f06a"');
$policon-exe: unquote('"\\e918"');
$policon-export: unquote('"\\e929"');
$policon-external-link: unquote('"\\f08e"');
$policon-eye: unquote('"\\f06e"');
$policon-eye-cancel: unquote('"\\e948"');
$policon-eye-slash: unquote('"\\f070"');
$policon-file: unquote('"\\e92a"');
$policon-file-bis: unquote('"\\f15b"');
$policon-file-contract: unquote('"\\e900"');
$policon-file-export-solid: unquote('"\\e936"');
$policon-file-invoice-dollar: unquote('"\\e901"');
$policon-file_present: unquote('"\\e939"');
$policon-filters: unquote('"\\e92b"');
$policon-flow: unquote('"\\e93b"');
$policon-folder: unquote('"\\e919"');
$policon-folder-settings: unquote('"\\e904"');
$policon-folder-zip: unquote('"\\e91a"');
$policon-globe: unquote('"\\f0ac"');
$policon-home-pol: unquote('"\\e905"');
$policon-key: unquote('"\\e92c"');
$policon-list: unquote('"\\f03a"');
$policon-list-check: unquote('"\\e951"');
$policon-mail: unquote('"\\e949"');
$policon-mail-historical: unquote('"\\e90e"');
$policon-mails-received: unquote('"\\e907"');
$policon-mails-send: unquote('"\\e906"');
$policon-map: unquote('"\\e92d"');
$policon-map-marker: unquote('"\\f041"');
$policon-minus: unquote('"\\f068"');
$policon-move-client: unquote('"\\e93a"');
$policon-ongoing-task: unquote('"\\e90c"');
$policon-p-square: unquote('"\\e94a"');
$policon-paragraph: unquote('"\\e94b"');
$policon-pdf: unquote('"\\e91b"');
$policon-percent: unquote('"\\e94c"');
$policon-phone: unquote('"\\f095"');
$policon-phone-alt: unquote('"\\e94d"');
$policon-plus: unquote('"\\f067"');
$policon-plus-circle: unquote('"\\f055"');
$policon-question: unquote('"\\f128"');
$policon-quit: unquote('"\\e92e"');
$policon-quote: unquote('"\\e90b"');
$policon-refresh: unquote('"\\e938"');
$policon-return: unquote('"\\e92f"');
$policon-search: unquote('"\\e903"');
$policon-search-plus: unquote('"\\f00e"');
$policon-sign-out: unquote('"\\f08b"');
$policon-sort: unquote('"\\f0dc"');
$policon-sort-down: unquote('"\\f0dd"');
$policon-sort-up: unquote('"\\f0de"');
$policon-store-alt: unquote('"\\e902"');
$policon-subrequests: unquote('"\\e953"');
$policon-svg: unquote('"\\e91d"');
$policon-tab: unquote('"\\e94e"');
$policon-tags: unquote('"\\f02c"');
$policon-tasks: unquote('"\\f0ae"');
$policon-times-circle: unquote('"\\f057"');
$policon-tools: unquote('"\\e930"');
$policon-trash: unquote('"\\f1f8"');
$policon-trending-down: unquote('"\\e954"');
$policon-trending-up: unquote('"\\e955"');
$policon-txt: unquote('"\\e931"');
$policon-unique: unquote('"\\e94f"');
$policon-unlock: unquote('"\\f09c"');
$policon-user: unquote('"\\f007"');
$policon-user-link: unquote('"\\e93e"');
$policon-validation: unquote('"\\e91e"');
$policon-video-camera: unquote('"\\f03d"');
$policon-warning: unquote('"\\e932"');
$policon-web: unquote('"\\e950"');
$policon-width-1-3: unquote('"\\e93f"');
$policon-width-1-4: unquote('"\\e940"');
$policon-width-2-3: unquote('"\\e941"');
$policon-width-3-4: unquote('"\\e942"');
$policon-width-full: unquote('"\\e943"');
$policon-width-half: unquote('"\\e944"');
$policon-zip: unquote('"\\e933"');

